import { BusinessException } from "@/services/middler/errors";
import { NotFound } from "@/services/middler/errors.js";
import { Repository } from "pinia-orm";
import { useLoadingStore } from "@/stores/loading";
import Place from "@/models/Place";
import PlaceService from "@/services/middler/PlaceService";

export default class PlaceRepository extends Repository {
  use = Place;
  service = new PlaceService();

  async search(
    tripId,
    placeTypes,
    cuisines,
    priceRanges,
    vehicleModes,
    insideParis
  ) {
    const loadingStore = useLoadingStore();
    loadingStore["place.search"] = true;
    try {
      const results = await this.service.search(
        tripId,
        placeTypes,
        cuisines,
        priceRanges,
        vehicleModes,
        insideParis
      );
      results.forEach((place) => {
        place.trips = [
          {
            id: tripId,
          },
        ];
        place.isSearchResult = true;
      });
      // Delete places that belong to the trip and don't have a poll
      this.whereHas("trips", (query) => query.where("id", tripId))
        .whereDoesntHave("polls")
        .delete();

      // Set the remaining places as not search results
      this.whereHas("trips", (query) => query.where("id", tripId)).update({
        isSearchResult: false,
      });

      // Save the new search results
      this.save(results);
    } catch (error) {
      if (error instanceof NotFound) {
        return;
      } else if (
        error instanceof BusinessException &&
        error.code === "outdated_trip"
      ) {
        return;
      } else if (
        error instanceof BusinessException &&
        error.code === "no_trip_user"
      ) {
        return;
      } else {
        throw error;
      }
    } finally {
      loadingStore["place.search"] = false;
    }
  }

  async list(tripId) {
    const loadingStore = useLoadingStore();
    loadingStore["place.list"] = true;
    try {
      const results = await this.service.list(tripId);
      results.forEach((place) => {
        place.trips = [
          {
            id: tripId,
          },
        ];
      });
      // Delete places that belong to the trip and have a poll
      this.whereHas("trips", (query) => query.where("id", tripId))
        .whereHas("polls")
        .delete();
      // Save results
      this.save(results);
    } catch (error) {
      if (error instanceof NotFound) {
        return;
      } else if (
        error instanceof BusinessException &&
        error.code === "outdated_trip"
      ) {
        return;
      } else if (
        error instanceof BusinessException &&
        error.code === "no_trip_user"
      ) {
        return;
      } else {
        throw error;
      }
    } finally {
      loadingStore["place.list"] = false;
    }
  }
}
