<template>
  <v-dialog fullscreen transition="" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" color="transparent" flat>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="flex-grow-1 overflow-y-auto">
        <div class="mb-5 text-h5">{{ $t("tripUserEdit.title") }}</div>
        <div class="mb-5">{{ $t("tripUserEdit.description") }}</div>
        <location-dialog
          v-model="location"
          :label="$t('tripUserEdit.locationInput.label')"
          :language="deviceStore.language"
        />
        <travel-mode-toggle
          v-model="travelMode"
          :label="$t('tripUserEdit.modeSelectLabel')"
        />
      </v-container>
      <v-expand-transition>
        <v-footer v-if="showUpdateBtn">
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              @click="update"
            >
              {{ $t("tripUserEdit.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapStores } from "pinia";
import { refreshPlaces } from "@/utils/place";
import { useDeviceStore } from "@/stores/device";
import LocationDialog from "@/components/dialogs/LocationDialog.vue";
import TravelModeToggle from "@/components/inputs/TravelModeToggle.vue";

export default {
  components: {
    LocationDialog,
    TravelModeToggle,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    const tripId = this.$route.params.tripId;
    const userId = this.$route.params.userId;
    const tripUser = this.$tripUserRepo
      .where("tripId", tripId)
      .where("userId", userId)
      .with("user")
      .first();
    const location = {
      lat: tripUser.lat,
      lng: tripUser.lng,
      address: tripUser.address,
    };
    return {
      location,
      showBackBtn: false,
      travelMode: tripUser.travelMode,
      tripId,
      tripUser,
      userId,
      wasAddressEmpty: !tripUser.address,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    showUpdateBtn() {
      return (
        this.location.address &&
        (this.location.address !== this.tripUser.address ||
          this.travelMode !== this.tripUser.travelMode)
      );
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: "tripUsers",
        params: { tripId: this.tripId },
      });
    },
    close() {
      this.$router.push({
        name: "tripInfo",
        params: { tripId: this.tripId },
      });
    },
    async update() {
      this.tripUser.address = this.location.address;
      this.tripUser.lat = this.location.lat;
      this.tripUser.lng = this.location.lng;
      this.tripUser.travelMode = this.travelMode;
      await this.$tripUserRepo.update(this.tripUser);
      refreshPlaces(this.tripId);
      if (this.wasAddressEmpty) {
        this.$router.push({
          name: "tripMap",
          params: { tripId: this.tripId },
        });
      } else {
        this.close();
      }
    },
  },
};
</script>
