import BaseService from "@/services/middler/BaseService";

export default class MutedTripService extends BaseService {
  basename = "muted-trips";

  async list() {
    const response = await this.client.get(this.basename);
    return response.data;
  }

  async create(data) {
    const response = await this.client.post(this.basename, data);
    return response.data;
  }

  async delete(id) {
    await this.client.delete(`${this.basename}/${id}`);
  }
}
