var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-main',[_c('v-container',{staticClass:"mt-3"},[_c('v-sheet',{staticClass:"mb-4 pa-4 rounded-xl"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("tripInfo.details"))+" "),(_vm.authTripUser?.isAdmin)?_c('v-btn',{attrs:{"color":"primary","icon":"","to":{ name: 'tripEdit', params: { tripId: _vm.tripId } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker")]),(_vm.trip.chosenPlace)?_c('router-link',{attrs:{"to":{
              name: 'tripPlace',
              params: { tripId: _vm.tripId, placeId: _vm.trip.chosenPlace.id },
            }}},[_vm._v(_vm._s(_vm.trip.chosenPlace.name)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t(`placeTypes.${_vm.trip.placeType}`)))])],1),_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.date)+" ")],1),_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-time-four-outline")]),_vm._v(" "+_vm._s(_vm.time)+" ")],1),(_vm.trip.description)?_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.trip.description)+" ")]):_vm._e(),(_vm.trip.chosenPlace)?_c('add-to-calendar-btn',{staticClass:"mt-1",attrs:{"trip-id":_vm.tripId}}):_vm._e()],1),_c('v-sheet',{staticClass:"mb-4 pa-4 rounded-xl"},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("tripInfo.participants")))]),_c('div',{staticClass:"d-flex align-center"},[_c('user-avatar-list',{attrs:{"trip-id":_vm.tripId}}),(_vm.authTripUser?.joined)?_c('router-link',{staticClass:"ml-2",attrs:{"to":{ name: 'tripUsers', params: { tripId: _vm.tripId } }}},[_vm._v(" "+_vm._s(_vm.$t("tripInfo.seeMore"))+" ")]):_vm._e()],1),(_vm.authTripUser?.isAdmin && !_vm.trip.chosenPlace)?_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","text":"","to":{ name: 'tripUserAdd', params: { tripId: _vm.tripId } }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("tripInfo.addParticipants"))+" ")],1):_vm._e()],1),(_vm.authTripUser?.joined)?_c('v-sheet',{staticClass:"mb-4 pa-4 rounded-xl"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("tripInfo.myDepartureAddress"))+" "),_c('v-btn',{attrs:{"color":"primary","icon":"","to":{
              name: 'tripUserEdit',
              params: { tripId: _vm.tripId, userId: _vm.authTripUser.userId },
            }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-chip',{staticClass:"mr-1",attrs:{"color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.travelModes[_vm.authTripUser.travelMode].icon)+" ")])],1),_vm._v(" "+_vm._s(_vm.authTripUser.address || _vm.$t("tripInfo.noDepartureAddress"))+" ")],1):_vm._e()],1)],1),(!_vm.authTripUser?.joined)?_c('v-footer',{attrs:{"app":""}},[_c('v-container',[_c('v-btn',{staticClass:"my-3",attrs:{"block":"","color":"primary","large":"","rounded":""},on:{"click":_vm.join}},[_vm._v(" "+_vm._s(_vm.$t("tripInfo.join"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }