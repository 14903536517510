<template>
  <div class="fill-height">
    <v-main class="fill-height">
      <v-container v-if="loading" class="d-flex justify-center" fill-height>
        <v-progress-circular
          color="primary"
          indeterminate
          :size="70"
        ></v-progress-circular>
      </v-container>
      <v-container v-else class="d-flex flex-column">
        <div
          v-for="(message, index) in messages"
          :id="`message-${message.id}`"
          :key="message.id"
          ref="messages"
          class="d-flex flex-column"
        >
          <span
            v-if="isNewDay(messages[index - 1], message)"
            class="align-self-center grey--text mb-2 text--darken-3 text-overline"
          >
            {{ isoStringToLocalDate(message.created) }}
          </span>
          <span
            v-if="message.isSystem"
            class="align-self-center grey--text mb-1 text-caption text--darken-3"
          >
            {{ message.text }}
          </span>
          <div
            v-if="!message.isSystem"
            class="d-flex align-end"
            :class="[
              isAuthUserMessage(message) ? 'justify-end' : 'justify-start',
              marginClass(messages[index - 1], message, messages[index + 1]),
            ]"
            style="width: 100%"
          >
            <user-avatar
              v-if="!isAuthUserMessage(message)"
              class="mr-2"
              :user="message.sender"
            />
            <v-card
              class="px-3 py-1"
              :class="[
                bubbleClass(messages[index - 1], message, messages[index + 1]),
              ]"
              :color="getColor(message)"
              :max-width="bubbleMaxWidth"
              :width="'fit-content'"
            >
              <div v-if="isOtherUserMessage(message)">
                {{ message.sender.fullName }}
              </div>
              <div class="message">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-html="
                    anchorme({
                      input: message.text,
                      options: {
                        attributes: {
                          target: '_blank',
                          referrerpolicy: 'no-referrer',
                        },
                      },
                    })
                  "
                ></div>
                <!-- eslint-enable vue/no-v-html -->
                <span
                  v-if="isLastMessage(message, messages[index + 1])"
                  class="time"
                >
                  {{ isoStringToLocalTime(message.created) }}
                </span>
              </div>
            </v-card>
          </div>
          <poll-card
            v-if="message.data?.pollId"
            class="align-self-center mb-4"
            :poll-id="message.data.pollId"
          />
        </div>
      </v-container>
    </v-main>
    <v-footer app>
      <v-container>
        <v-fab-transition>
          <v-btn
            v-if="!isOnLastPage"
            class="scroll-btn"
            color="pink"
            fab
            x-small
            @click="scrollToBottom"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-fab-transition>
        <form class="d-flex flex-grow-1" @submit.prevent="submit">
          <v-text-field
            v-model.trim="newMessageText"
            class="mr-2"
            color="primary"
            dense
            hide-details
            outlined
            rounded
          />
          <v-btn
            color="primary"
            :disabled="!newMessageText"
            fab
            small
            type="submit"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </form>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import { useLoadingStore } from "@/stores/loading";
import { useNetworkStore } from "@/stores/network";
import PollCard from "@/components/PollCard";
import UserAvatar from "@/components/UserAvatar.vue";
import anchorme from "anchorme";
import waitForElement from "@/utils/waitForElement";

export default {
  name: "TripMessages",
  components: {
    PollCard,
    UserAvatar,
  },
  data() {
    return {
      anchorme,
      isOnLastPage: true,
      lastScrollTop: null,
      newMessageText: "",
    };
  },
  computed: {
    ...mapStores(useDeviceStore, useLoadingStore, useNetworkStore),
    bubbleMaxWidth() {
      return this.$vuetify.breakpoint.mobile ? "90%" : "60%";
    },
    loading() {
      return this.loadingStore["message.list"];
    },
    messageId() {
      return this.$route.params.messageId;
    },
    messages() {
      return this.$messageRepo
        .where("tripId", this.tripId)
        .with("sender")
        .orderBy("created")
        .get();
    },
    tripId() {
      return this.$route.params.tripId;
    },
    unreadMessageCount() {
      return this.$messageRepo.getUnreadMessageCount(
        this.tripId,
        this.$userRepo.authUser.id
      );
    },
  },
  watch: {
    // If the user is on the last page and new messages are added, scroll to the bottom and mark
    // messages as read
    messages: {
      handler(newVal, oldVal) {
        if (this.isOnLastPage && newVal.length > (oldVal || []).length) {
          this.$nextTick(() => {
            this.scrollToBottom();
            this.markAsRead();
          });
        }
      },
    },
    // When the user arrives on the last page, mark messages as read
    isOnLastPage: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.markAsRead();
        }
      },
      immediate: true,
    },
    // Scroll to the message with the given ID
    messageId: {
      handler() {
        if (this.messageId) {
          const elementId = `#message-${this.messageId}`;
          waitForElement(elementId).then(() => {
            this.$vuetify.goTo(elementId);
          });
        }
      },
      immediate: true,
    },
  },
  activated() {
    // Check if the user is on the last page
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    this.isOnLastPage = scrollHeight - scrollTop - clientHeight < clientHeight;

    // Add scroll event listener
    window.addEventListener("scroll", this.onScroll);

    this.$nextTick(() => {
      if (this.lastScrollTop) {
        // Go back to the last scroll position
        document.documentElement.scrollTop = this.lastScrollTop;
      } else {
        // Else, scroll to the bottom
        this.scrollToBottom();
      }
    });
    // Remove delivered notifications related to this trip
    if (Capacitor.isNativePlatform()) {
      this.removeDeliveredNotifications();
    }
  },
  deactivated() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    bubbleClass(previousMessage, message, nextMessage) {
      const cls = {};
      if (this.isAuthUserMessage(message)) {
        cls["rounded-l-xl"] = true;
        cls["rounded-tr-xl"] = this.isFirstMessage(previousMessage, message);
        cls["rounded-br-xl"] = this.isLastMessage(message, nextMessage);
      }
      if (this.isOtherUserMessage(message)) {
        cls["rounded-r-xl"] = true;
        cls["rounded-tl-xl"] = this.isFirstMessage(previousMessage, message);
        cls["rounded-bl-xl"] = this.isLastMessage(message, nextMessage);
      }
      return cls;
    },
    getColor(message) {
      return this.isAuthUserMessage(message) ? "primary" : "secondary";
    },
    isAuthUserMessage(message) {
      return message.senderId === this.$userRepo.authUser.id;
    },
    isOtherUserMessage(message) {
      return message.senderId !== this.$userRepo.authUser.id;
    },
    isoStringToLocalTime(isoString) {
      const options = { hour: "numeric", minute: "numeric", hour12: false };
      return new Date(isoString).toLocaleTimeString(
        this.deviceStore.language,
        options
      );
    },
    isoStringToLocalDate(isoString) {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
      };
      return new Date(isoString).toLocaleDateString(
        this.deviceStore.language,
        options
      );
    },
    isNewDay(previousMessage, message) {
      return (
        previousMessage === undefined ||
        this.isoStringToLocalDate(previousMessage.created) !==
          this.isoStringToLocalDate(message.created)
      );
    },
    isFirstMessage(previousMessage, message) {
      return (
        previousMessage === undefined ||
        previousMessage.isSystem ||
        previousMessage.senderId !== message.senderId ||
        this.isoStringToLocalTime(previousMessage.created) !==
          this.isoStringToLocalTime(message.created)
      );
    },
    isLastMessage(message, nextMessage) {
      return (
        nextMessage === undefined ||
        nextMessage.senderId !== message.senderId ||
        nextMessage.isSystem ||
        this.isoStringToLocalTime(nextMessage.created) !==
          this.isoStringToLocalTime(message.created)
      );
    },
    marginClass(previousMessage, message, nextMessage) {
      const cls = {};
      if (this.isFirstMessage(previousMessage, message)) {
        cls["mt-4"] = true;
      }
      if (this.isLastMessage(message, nextMessage)) {
        cls["mb-4"] = true;
      } else {
        cls["mb-1"] = true;
      }
      return cls;
    },
    markAsRead() {
      if (this.unreadMessageCount > 0) {
        this.$messageRepo.markAsRead(this.tripId);
      }
    },
    onScroll() {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;
      this.isOnLastPage =
        scrollHeight - scrollTop - clientHeight < clientHeight;
      this.lastScrollTop = scrollTop;
    },
    async removeDeliveredNotifications() {
      // If the platform is Android, remove all delivered notifications
      if (Capacitor.getPlatform() === "android") {
        FirebaseMessaging.removeAllDeliveredNotifications();
        return;
      }

      // If the platform is iOS, remove delivered notifications related to this trip
      const { notifications: deliveredNotifications } =
        await FirebaseMessaging.getDeliveredNotifications();
      if (!deliveredNotifications || deliveredNotifications.length === 0)
        return;

      const notificationsToRemove = deliveredNotifications.filter(
        (notification) => notification.data.trip_id === this.tripId
      );
      if (notificationsToRemove.length === 0) return;

      FirebaseMessaging.removeDeliveredNotifications({
        notifications: notificationsToRemove,
      });
    },
    scrollToBottom() {
      const { scrollHeight } = document.documentElement;
      const behavior = this.isOnLastPage ? "smooth" : "instant";
      window.scrollTo({
        top: scrollHeight,
        behavior,
      });
    },
    submit() {
      this.$messageRepo.create(this.tripId, this.newMessageText);
      this.newMessageText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  align-items: flex-end;
  color: white;
  display: flex;
  justify-content: space-between;

  ::v-deep a {
    color: white !important;
  }

  .time {
    font-size: 0.8em;
    margin-left: 8px;
    opacity: 0.7;
  }
}
.v-footer {
  .container {
    position: relative;

    .scroll-btn {
      position: absolute;
      bottom: 100px;
      right: 0px;
    }
  }
}
</style>
