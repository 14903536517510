import { Model } from "pinia-orm";

export default class MutedTrip extends Model {
  static entity = "mutedTrips";
  static fields() {
    return {
      id: this.string(null),
      tripId: this.string(null),
    };
  }
}
