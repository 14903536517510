<template>
  <v-dialog fullscreen transition="" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" color="transparent" flat>
        <v-spacer />
        <v-btn exact icon :to="{ name: 'tripInfo', params: { tripId } }">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <validation-observer
        v-slot="{ invalid }"
        class="d-flex flex-column flex-grow-1 overflow-y-auto"
        tag="form"
        @submit.prevent="onSubmit"
      >
        <v-container class="flex-grow-1 overflow-y-auto">
          <div>{{ $t("tripForm.placeType.description") }}</div>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('tripForm.placeType')"
            rules="required"
            vid="placeType"
          >
            <place-type-toggle v-model="placeType" :errors="errors" />
          </validation-provider>
          <div class="mt-5">{{ $t("tripForm.datetime.description") }}</div>
          <div class="d-flex">
            <validation-provider
              v-slot="{ errors }"
              class="mr-3"
              :name="$t('tripForm.date')"
              rules="required"
              style="width: 100%"
              vid="date"
            >
              <date-input
                v-model="date"
                :errors="errors"
                :label="$t('tripForm.date')"
                :language="deviceStore.language"
                :min="minDate"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('tripForm.time')"
              rules="required"
              style="width: 100%"
              vid="time"
            >
              <time-input
                v-model="time"
                :errors="errors"
                :label="$t('tripForm.time')"
                :min="minTime"
              />
            </validation-provider>
          </div>
          <div class="mt-5">{{ $t("tripForm.info.description") }}</div>
          <v-text-field v-model.trim="name" :label="$t('tripForm.name')" />
          <v-textarea
            v-model.trim="description"
            :label="$t('tripForm.description')"
          />
        </v-container>
        <v-footer>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              :disabled="invalid"
              large
              rounded
              type="submit"
            >
              {{ $t("tripEdit.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { refreshPlaces } from "@/utils/place";
import { useDeviceStore } from "@/stores/device";
import DateInput from "@/components/inputs/DateInput.vue";
import PlaceTypeToggle from "@/components/inputs/PlaceTypeToggle.vue";
import TimeInput from "@/components/inputs/TimeInput.vue";

export default {
  components: {
    DateInput,
    PlaceTypeToggle,
    TimeInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const tripId = this.$route.params.tripId;
    const trip = this.$tripRepo.find(tripId);
    const arrivalLocalDatetime = new Date(trip.arrivalDatetime).toLocaleString(
      "sv-SE",
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }
    );
    return {
      placeType: trip.placeType,
      date: arrivalLocalDatetime.split(" ")[0],
      time: arrivalLocalDatetime.split(" ")[1],
      name: trip.name,
      description: trip.description,
      tripId,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    minDate() {
      const now = new Date();
      return this.toTimezoneOffsetISOString(now).split("T")[0];
    },
    minTime() {
      const now = new Date();
      if (this.date === this.minDate) {
        return this.toTimezoneOffsetISOString(now).split("T")[1].split(".")[0];
      }
      return null;
    },
  },
  methods: {
    async onSubmit() {
      const arrivalDatetime = new Date(this.date + " " + this.time);
      await this.$tripRepo.update({
        id: this.tripId,
        placeType: this.placeType,
        arrivalDatetime,
        name: this.name,
        description: this.description,
      });
      refreshPlaces(this.tripId);
      this.$router.push({
        name: "tripInfo",
        params: { tripId: this.tripId },
      });
    },
    toTimezoneOffsetISOString(dateString) {
      const date = new Date(dateString);
      return new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
    },
  },
};
</script>
