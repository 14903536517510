<template>
  <v-snackbar
    v-model="snackbar"
    bottom
    color="primary"
    text
    :timeout="trip.placesRefreshRequired ? -1 : 3000"
    @input="onSnackbarInput"
  >
    {{ currentSnackbarMessage.text }}
    <template #action="{ attrs }">
      <v-btn
        v-if="currentSnackbarMessage.data?.pollId"
        color="primary"
        icon
        v-bind="attrs"
        @click="onPollBtnClick"
      >
        <v-icon>mdi-thumbs-up-down</v-icon>
      </v-btn>
      <v-btn
        v-if="trip.placesRefreshRequired"
        color="primary"
        icon
        v-bind="attrs"
        @click="onRefreshBtnClick"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { refreshPlaces } from "@/utils/place";
import camelcaseKeysDeep from "camelcase-keys-deep";
import websocket from "@/websocket";

export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentSnackbarMessage: "",
      snackbar: false,
      snackbarMessages: [],
    };
  },
  computed: {
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
  },
  created() {
    websocket.addEventListener("message", this.onWebsocketMessage);
  },
  beforeDestroy() {
    websocket.removeEventListener("message", this.onWebsocketMessage);
  },
  methods: {
    addToSnackbarMessages(message) {
      this.snackbarMessages.push(message);
      if (!this.snackbar) {
        this.showNextSnackbarMessage();
      }
    },
    onSnackbarInput() {
      setTimeout(() => {
        this.showNextSnackbarMessage();
      }, 500);
    },
    onWebsocketMessage(event) {
      const data = JSON.parse(event["data"]);
      const message = camelcaseKeysDeep(data.data);
      if (data.resource === "message" && data.action === "create") {
        if (
          message.isSystem &&
          message.tripId === this.tripId &&
          message.senderId !== this.$userRepo.authUser.id &&
          this.$route.name !== "tripMessages"
        ) {
          this.addToSnackbarMessages(message);
        }
      }
    },
    async onPollBtnClick() {
      this.$router.push({
        name: "tripMessages",
        params: { tripId: this.tripId, messageId: this.customMessageId },
      });
      this.snackbar = false;
    },
    async onRefreshBtnClick() {
      await refreshPlaces(this.tripId);
      this.snackbar = false;
      setTimeout(() => {
        this.showNextSnackbarMessage();
      }, 500);
    },
    showNextSnackbarMessage() {
      if (this.snackbarMessages.length > 0) {
        this.currentSnackbarMessage = this.snackbarMessages.shift();
        this.snackbar = true;
      }
    },
  },
};
</script>
