import { render, staticRenderFns } from "./UserAuth.vue?vue&type=template&id=dac4e38a&scoped=true"
import script from "./UserAuth.vue?vue&type=script&lang=js"
export * from "./UserAuth.vue?vue&type=script&lang=js"
import style0 from "./UserAuth.vue?vue&type=style&index=0&id=dac4e38a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@2.8.8_vue-template-compiler@2.7.16_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dac4e38a",
  null
  
)

export default component.exports