<template>
  <div class="fill-height">
    <message-snackbar v-if="trip" :trip-id="tripId" />
    <v-app-bar
      app
      color="primary"
      dark
      :extension-height="$vuetify.breakpoint.mobile ? 48 : 64"
      flat
    >
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title v-if="trip" class="mr-8">
        {{ trip.name }}
      </v-toolbar-title>
      <v-spacer />
      <trip-detail-menu v-if="trip" :trip-id="tripId" />
      <template v-if="hasJoined" #extension>
        <v-slide-y-transition>
          <v-tabs
            v-if="mounted"
            background-color="primary"
            dark
            fixed-tabs
            :icons-and-text="!$vuetify.breakpoint.mobile"
            slider-color="secondary"
          >
            <v-tab exact :to="{ name: 'tripInfo', params: { tripId } }">
              <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
                {{ $t("tripDetail.tabs.details") }}
              </span>
              <v-icon>mdi-information</v-icon>
            </v-tab>
            <v-tab exact :to="{ name: 'tripMessages', params: { tripId } }">
              <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
                {{ $t("tripDetail.tabs.messages") }}
              </span>
              <v-badge
                v-if="unreadMessageCount"
                :content="unreadMessageCount"
                overlap
              >
                <v-icon>mdi-chat</v-icon>
              </v-badge>
              <v-icon v-else>mdi-chat</v-icon>
            </v-tab>
            <v-tab exact :to="placeTabRoute">
              <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
                {{ $t("tripDetail.tabs.places") }}
              </span>
              <v-icon>mdi-map-marker-multiple</v-icon>
            </v-tab>
          </v-tabs>
        </v-slide-y-transition>
      </template>
    </v-app-bar>
    <v-fade-transition>
      <div v-if="mounted" class="fill-height">
        <v-main v-if="!trip" class="fill-height">
          <v-container class="d-flex justify-center" fill-height>
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
            ></v-progress-circular>
          </v-container>
        </v-main>
        <keep-alive v-else include="TripMap,TripMessages">
          <router-view />
        </keep-alive>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { NotFound } from "@/services/middler/errors.js";
import { mapStores } from "pinia";
import { refreshPlaces } from "@/utils/place";
import { useConfigStore } from "@/stores/config";
import { useLoadingStore } from "@/stores/loading";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import MessageSnackbar from "@/components/MessageSnackbar.vue";
import TripDetailMenu from "@/components/TripDetailMenu.vue";

export default {
  components: {
    GoBackBtn,
    MessageSnackbar,
    TripDetailMenu,
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    ...mapStores(useConfigStore, useLoadingStore),
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    hasJoined() {
      return this.authTripUser && this.authTripUser.joined;
    },
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
    tripId() {
      return this.$route.params.tripId;
    },
    placeTabRoute() {
      if (this.authTripUser.lat) {
        return {
          name: this.configStore.placeViewName,
          params: { tripId: this.tripId },
        };
      } else {
        return {
          name: "tripUserEdit",
          params: { tripId: this.tripId, userId: this.$userRepo.authUser.id },
        };
      }
    },
    unreadMessageCount() {
      return this.$messageRepo.getUnreadMessageCount(
        this.tripId,
        this.$userRepo.authUser.id
      );
    },
  },
  async created() {
    // Extract shareKey and tripId from the route
    const shareKey = this.$route.query.shareKey;
    const tripId = this.$route.params.tripId;

    try {
      // Retrieve trip
      await this.$tripRepo.retrieve(tripId, shareKey);

      // Define an array to store API calls
      const apiCalls = [];

      // Fetch users related to the trip
      apiCalls.push(this.$userRepo.listByTrip(tripId, shareKey));

      // If shareKey is not provided, fetch messages, places, and polls
      if (!shareKey) {
        // Fetch messages and mark them as received
        apiCalls.push(
          this.$messageRepo.list(tripId).then(() => {
            this.$messageRepo.markAsReceived(tripId);
          })
        );
        // Fetch places
        apiCalls.push(refreshPlaces(tripId));
        // Fetch polls
        apiCalls.push(this.$pollRepo.list(tripId));
      }

      // Execute all API calls concurrently
      await Promise.all(apiCalls);
    } catch (error) {
      // Handle NotFound error by redirecting to trip list
      if (error instanceof NotFound) {
        this.$router.push({ name: "tripList" });
      }
    }
  },
  mounted() {
    this.mounted = true;
  },
};
</script>
