import { Repository } from "pinia-orm";
import MutedTrip from "@/models/MutedTrip";
import MutedTripService from "@/services/middler/MutedTripService";

export default class MutedTripRepository extends Repository {
  use = MutedTrip;
  service = new MutedTripService();

  async list() {
    const results = await this.service.list();
    this.save(results);
  }

  async create(tripId) {
    const response = await this.service.create({ tripId });
    return response.data;
  }

  async remove(mutedTrip) {
    await this.service.delete(mutedTrip.id);
    this.destroy(mutedTrip.id);
  }
}
