import { Repository } from "pinia-orm";
import { useLoadingStore } from "@/stores/loading";
import Trip from "@/models/Trip";
import TripService from "@/services/middler/TripService";

export default class TripRepository extends Repository {
  use = Trip;
  service = new TripService();

  async list() {
    const loadingStore = useLoadingStore();
    loadingStore["trip.list"] = true;
    const results = await this.service.list();
    this.flush();
    this.save(results);
    loadingStore["trip.list"] = false;
  }

  async create(placeType, arrivalDatetime, name, description) {
    const loadingStore = useLoadingStore();
    loadingStore["trip.create"] = true;
    const result = await this.service
      .create({
        placeType,
        arrivalDatetime,
        name,
        description,
      })
      .finally(() => {
        loadingStore["trip.create"] = false;
      });
    this.save(result);
    return result;
  }

  async retrieve(id, shareKey) {
    const params = shareKey ? { share_key: shareKey } : {};
    const result = await this.service.retrieve(id, params);
    this.save(result);
  }

  async update(trip) {
    const result = await this.service.partialUpdate(trip.id, trip);
    this.save(result);
  }

  async invite(id, invitedUserId) {
    const result = await this.service.invite(id, invitedUserId);
    this.save(result);
  }

  async join(id, shareKey) {
    const result = await this.service.join(id, shareKey);
    this.save(result);
  }

  async remove(trip) {
    await this.service.delete(trip.id);
    this.destroy(trip.id);
  }
}
