var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","transition":"","value":true}},[_c('v-sheet',{staticClass:"d-flex fill-height flex-column"},[_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"color":"transparent","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('router-view'),_c('v-container',{staticClass:"flex-grow-1 overflow-y-auto"},[_c('div',{staticClass:"mb-5 text-h5"},[_vm._v(_vm._s(_vm.$t("tripUsers.title")))]),_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.tripUsers),function(tripUser){return _c('v-list-item',{key:tripUser.id,attrs:{"id":`user-${tripUser.userId}`}},[_c('user-avatar',{staticClass:"mr-3",attrs:{"admin-badge":tripUser.isAdmin,"badge-icon":_vm.travelModes[tripUser.travelMode].icon,"grayscale":!tripUser.joined,"position":"relative","size":"48","user":tripUser.user}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(tripUser.user.fullName)+" ")]),(!tripUser.joined)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("tripUsers.notJoined"))+" ")]):_vm._e(),(tripUser.joined && !tripUser.address)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("tripUsers.noDepartureAddress"))+" ")]):_vm._e()],1),_c('v-card-actions',[(tripUser.lat !== null && tripUser.lng !== null)?_c('v-btn',{attrs:{"color":"primary","fab":"","rounded":"","to":{
                name: 'tripMapUser',
                params: { tripId: _vm.tripId, userId: tripUser.userId },
              },"x-small":""}},[_c('v-icon',[_vm._v("mdi-map")])],1):_vm._e()],1)],1)}),1)],1),(_vm.authTripUser?.isAdmin)?_c('v-footer',[_c('v-container',[_c('v-btn',{staticClass:"my-3",attrs:{"block":"","color":"primary","large":"","rounded":"","to":{
            name: 'tripUserAdd',
            params: { tripId: _vm.tripId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("tripUsers.addParticipants"))+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }