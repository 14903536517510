var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-input',{attrs:{"error-messages":_vm.errorMessages,"label":_vm.label}},[(!_vm.image)?_c('v-file-input',{attrs:{"accept":"image/*","clearable":false,"hide-details":"","placeholder":_vm.$t('imageInput.placeholder'),"prepend-icon":"mdi-camera","show-size":""},on:{"change":_vm.load}}):_vm._e(),(_vm.image)?_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"cropper-container"},[_c('cropper',{attrs:{"image-restriction":"stencil","resize-image":{
          adjustStencil: false,
        },"src":_vm.image,"stencil-component":"circle-stencil","stencil-props":{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        },"stencil-size":{
          width: 300,
          height: 300,
        }},on:{"change":_vm.change}}),(_vm.image)?_c('v-btn',{staticClass:"close-btn",attrs:{"fab":"","x-small":""},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }